export const SliderData = [

    {
        image:"Dibujos/Un dos.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/01123.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/01123 .jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/224.jpeg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/01123(2).jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/pour-soi.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/0,1,1....jpg",
        alt:"large-horizontal"
    }

];