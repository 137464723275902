export const SliderData = [
    {
        image:"Dibujos/goustos.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/habitos.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/Fantome.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/Ineinander.jpg",
        alt:"large-vertical"
    }
    

];