export const SliderData = [
    {
        image:"Dibujos/Sabotaje.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/angustia.jpg",
        alt:"large-horizontal"
    },
    {
        image: "Dibujos/Incomprendida.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/___.jpeg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/genero.JPG",
        alt:"larg-vertical"
    },
    {
        image:"Dibujos/SolNegro.jpeg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/Source.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/Voluntad-.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/tacto.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/pacto.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/CqD.jpg",
        alt:"large-vertical"
    }
    

];